import FormikControl from "../../utils/Formik/FormikControl";

const EmployeeBankDetails = () => {
	return (
		<>
			<h2 style={{ margin: "1rem 0" }}>Employee Bank Details</h2>
			<div className="main-form-container">
				<FormikControl
					control="input"
					type="text"
					label="Bank Holder Name"
					name="empBankHolderName"
					placeholder="Bank Holder Name"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Bank Name"
					name="empBankName"
					placeholder="Bank Name"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Branch Address"
					name="empBranchAddress"
					placeholder="Branch Address"
				/>
				<FormikControl
					control="input"
					type="text"
					label="IBAN Number"
					name="empIBANNumber"
					placeholder="IBAN Number"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Account Number"
					name="empAccountNumber"
					placeholder="Account Number"
				/>
				<FormikControl
					control="input"
					type="text"
					label="SWIFT Code"
					name="empSWIFTCode"
					placeholder="SWIFT Code"
				/>
			</div>
		</>
	);
};

export default EmployeeBankDetails;
