export const userMenu = [
    { linkTo: '/dashboard', menuItemText: 'Dashboard' },
    { linkTo: '/edm', menuItemText: 'Email Templates (EDM)' },
    { linkTo: '/brochure', menuItemText: 'Brochure Generator' },
    { linkTo: '/profile', menuItemText: 'Profile' },
]

export const religionOptions = [
    { key: 'Religion', value: '' },
    { key: 'Christianity', value: 'Christianity' },
    { key: 'Islam', value: 'Islam' },
    { key: 'Jainism', value: 'Jainism' },
    { key: 'Hinduism', value: 'Hinduism' },
    { key: 'Buddhism', value: 'Buddhism' },
    { key: 'Judaism', value: 'Judaism' },
    { key: 'Sikhism', value: 'Sikhism' },
    { key: 'Zoroastrianism', value: 'Zoroastrianism' },
    { key: 'Shinto', value: 'Shinto' },
]

export const genderOptions = [
    { key: 'Gender', value: '' },
    { key: 'Male', value: 'Male' },
    { key: 'Female', value: 'Female' },
]

export const maritalOptions = [
    { key: 'Marital Status', value: '' },
    { key: 'Single', value: 'Single' },
    { key: 'Married', value: 'Married' },
]

export const bloodGroupOptions = [
    { key: 'Blood Group', value: '' },
    { key: 'A+', value: 'A+' },
    { key: 'A-', value: 'A-' },
    { key: 'B+', value: 'B+' },
    { key: 'B-', value: 'B-' },
    { key: 'O+', value: 'O+' },
    { key: 'O-', value: 'O-' },
    { key: 'AB+', value: 'AB+' },
    { key: 'AB-', value: 'AB-' },
]

export const stateUAEOptions = [
    { key: 'State/Province (U.A.E.)', value: '' },
    { key: 'Abu Dhabi', value: 'Abu Dhabi' },
    { key: 'Dubai', value: 'Dubai' },
    { key: 'Sharjah', value: 'Sharjah' },
    { key: 'Ajman', value: 'Ajman' },
    { key: 'Umm Al-Quwain', value: 'Umm Al-Quwain' },
    { key: 'Fujairah', value: 'Fujairah' },
    { key: 'Ras Al Khaimah', value: 'Ras Al Khaimah' },
]

export const visaStatusOptions = [
    { key: 'Visa Status', value: '' },
    { key: 'Visit Visa', value: 'Visit Visa' },
    { key: 'XR Residence Visa', value: 'XR Residence Visa' },
    { key: 'Other Residence Visa', value: 'Other Residence Visa' },
]

export const empDepartmentOptions = [
    { key: 'Department', value: '' },
    { key: 'Sales', value: 'Sales' },
    { key: 'Admin', value: 'Admin' },
    { key: 'Operations', value: 'Operations' },
]

export const featuresListOptions = [
    { value: 'BALCONY', label: 'BALCONY' },
    { value: 'BBQ AREA', label: 'BBQ AREA' },
    { value: 'BUILT IN WARDROBES', label: 'BUILT IN WARDROBES' },
    { value: 'CABLE-READY', label: 'CABLE-READY' },
    { value: 'CENTRAL AIR CONDITIONING', label: 'CENTRAL AIR CONDITIONING' },
    { value: 'CHILDREN PLAY AREA', label: 'CHILDREN PLAY AREA' },
    { value: 'CHILDREN POOL', label: 'CHILDREN POOL' },
    { value: 'CONCIERGE SERVICE', label: 'CONCIERGE SERVICE' },
    { value: 'COVERED PARKING', label: 'COVERED PARKING' },
    { value: 'KITCHEN APPLIANCES', label: 'KITCHEN APPLIANCES' },
    { value: 'LOBBY IN BUILDING', label: 'LOBBY IN BUILDING' },
    { value: 'MAID SERVICE', label: 'MAID SERVICE' },
    { value: 'MAIDS ROOM', label: 'MAIDS ROOM' },
    { value: 'PETS ALLOWED', label: 'PETS ALLOWED' },
    { value: 'PRIVATE GARDEN', label: 'PRIVATE GARDEN' },
    { value: 'PRIVATE GYM', label: 'PRIVATE GYM' },
    { value: 'PRIVATE JACUZZI', label: 'PRIVATE JACUZZI' },
    { value: 'PRIVATE POOL', label: 'PRIVATE POOL' },
    { value: 'SEA/WATER VIEW', label: 'SEA/WATER VIEW' },
    { value: 'SECURITY', label: 'SECURITY' },
    { value: 'SHARED GYM', label: 'SHARED GYM' },
    { value: 'SHARED SWIMMING POOL', label: 'SHARED SWIMMING POOL' },
    { value: 'SPA', label: 'SPA' },
    { value: 'STUDY', label: 'STUDY' },
    { value: 'VASTU-COMPLIANT', label: 'VASTU-COMPLIANT' },
    { value: 'VIEW OF LANDMARK', label: 'VIEW OF LANDMARK' },
    { value: 'WALK-IN CLOSET', label: 'WALK-IN CLOSET' },
    { value: 'BASEMENT', label: 'BASEMENT' },
    { value: 'BASEMENT PARKING', label: 'BASEMENT PARKING' },
    { value: 'BEACH ACCESS', label: 'BEACH ACCESS' },
    { value: 'CARPETS', label: 'CARPETS' },
    { value: 'CITY VIEW', label: 'CITY VIEW' },
    { value: 'COMMUNITY VIEW', label: 'COMMUNITY VIEW' },
    { value: 'DRIVERS ROOM', label: 'DRIVERS ROOM' },
    { value: 'EAST ORIENTATION', label: 'EAST ORIENTATION' },
    { value: 'FULLY FITTED KITCHEN', label: 'FULLY FITTED KITCHEN' },
    { value: 'GARDEN VIEW', label: 'GARDEN VIEW' },
    { value: 'GAZEBO', label: 'GAZEBO' },
    { value: 'GOLF VIEW', label: 'GOLF VIEW' },
    { value: 'INDOOR SWIMMING POOL', label: 'INDOOR SWIMMING POOL' },
    { value: 'MAINTENANCE', label: 'MAINTENANCE' },
    { value: 'MARBLE FLOORS', label: 'MARBLE FLOORS' },
    { value: 'NORTH ORIENTATION', label: 'NORTH ORIENTATION' },
    { value: 'SOUTH ORIENTATION', label: 'SOUTH ORIENTATION' },
    { value: 'STORAGE ROOM', label: 'STORAGE ROOM' },
    { value: 'WEST ORIENTATION', label: 'WEST ORIENTATION' },
    { value: 'WOOD FLOORING', label: 'WOOD FLOORING' },

];