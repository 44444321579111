import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { XR_Logo_Black } from "../../assets";
import FormikControl from "../../utils/Formik/FormikControl";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";
import { LOGIN } from "../../apiStrings/users";

const initialValues = {
	empXRealtyEmail: "",
	empPassword: "",
};

const validationSchema = Yup.object({
	empXRealtyEmail: Yup.string()
		.matches(
			/^[A-Za-z0-9._%+-]+@xrealty\.ae$/,
			"Invalid work email, Please enter a valid work email! (example@xrealty.ae)"
		)
		.required("Work email address required! (example@xrealty.ae)"),
	empPassword: Yup.string().required("Password required!"),
});

function Login() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [errMessage, setErrMessage] = useState("");

	useEffect(() => {
		const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY);
		if (!isLoggedIn) {
			navigate("/");
		} else {
			navigate("/dashboard");
		}
	}, [navigate, isLoading]);

	const handlePasswordShowHide = () => {
		setShowPassword(!showPassword);
	};

	const onSubmit = async (values, { resetForm }) => {
		setShowPassword(false);
		setIsLoading(true);

		try {
			const response = await fetch(LOGIN, {
				method: "POST",
				body: JSON.stringify(values),
				headers: {
					"Content-Type": "application/json",
				},
			});

			const res = await response.json();
			setErrMessage(res);

			if (res.status === 200) {
				setIsLoading(false);
				resetForm({ values: "" });
				setShowPassword(false);
				localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res.data));
			} else {
				setIsLoading(false);
				localStorage.setItem(LOCAL_STORAGE_KEY, "");
			}
		} catch (err) {
			console.error(err);
		}

		setIsLoading(false);
	};

	return (
		<div className="login">
			<div className="auth-wrapper">
				<div className="auth-container">
					<div className="auth-header">
						<img src={XR_Logo_Black} alt="XRealty Portal" />

						<h2>Sign in</h2>
						<p>Welcome Back! Please enter your credentials.</p>
					</div>

					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formik) => {
							return (
								<Form>
									<FormikControl
										control="input"
										type="email"
										name="empXRealtyEmail"
										placeholder="XR Email Address"
									/>

									<div className="form-container">
										<div className="inner-form-container">
											<FormikControl
												control="input"
												type={showPassword ? "text" : "password"}
												name="empPassword"
												placeholder="********"
												style={{ width: "310px" }}
												autoComplete="off"
											/>
											{showPassword ? (
												<FaEyeSlash
													className="password-show-hide"
													onClick={handlePasswordShowHide}
												/>
											) : (
												<FaEye
													className="password-show-hide"
													onClick={handlePasswordShowHide}
												/>
											)}
										</div>
									</div>

									{errMessage.status == 200 ? (
										<p className="errMsgFinal">{errMessage.message}</p>
									) : (
										<p className="errMsg">{errMessage.message}</p>
									)}

									<button type="submit" disabled={!formik.isValid}>
										{isLoading ? (
											<img
												src="https://xrealty.sirv.com/Images/Loader/Infinity-loader-white.svg"
												alt="Infinity"
												className="loading"
											/>
										) : (
											<p style={{ padding: "16px 0" }}>Login</p>
										)}
									</button>
								</Form>
							);
						}}
					</Formik>

					<div className="extra-info">
						<Link to="/register">
							New to XRealty?{" "}
							<span style={{ fontWeight: "600", textDecoration: "underline" }}>
								Create an account
							</span>
						</Link>
						<Link to="/forgot-password">
							Oops!{" "}
							<span style={{ fontWeight: "600", textDecoration: "underline" }}>
								Forgot your password?
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
