export const BASE_URL = "https://xr.xrealty.ae/api/";

export const LOGIN = BASE_URL + "login.php";

export const REGISTER = BASE_URL + "register.php";

export const RESET_PWD = BASE_URL + "reset-password.php";

export const SET_USER = BASE_URL + "set-user.php";

export const GET_USER = BASE_URL + "get-user.php";

export const GET_PROJECT = BASE_URL + "get-project.php";
