import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { browserName } from "react-device-detect";
import { FaChrome, FaPrayingHands } from "react-icons/fa";

import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ChangeNewPassword from "./components/ForgotPassword/ChangeNewPassword";
import Error404 from "./components/Error404/Error404";
import Profile from "./components/Profile/Profile";
import EDM from "./components/EDM/EDM";
import BrochureGenerator from "./components/BrochureGenerator/BrochureGenerator";

const App = () => {
  return (
    <>
      {/* {
                browserName === "Chrome"
                    ? <Outlet /> : <h1 className='browserWarning'>Use&nbsp; <span style={{ textDecoration: "underline" }}> Chrome browser </span> &nbsp;for better performance&nbsp;<FaChrome />&nbsp;Thank You&nbsp;<FaPrayingHands /></h1>
            } */}
      <Outlet />
    </>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/edm",
        element: <EDM />,
      },
      {
        path: "/brochure",
        element: <BrochureGenerator />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/forgot-password/:emailId/:tokenId",
        element: <ChangeNewPassword />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
