import React from 'react'
import { Field, ErrorMessage } from 'formik'
import FormErrorText from './FormErrorText';

const Textarea = (props) => {
    const { label, name, ...rest } = props
    return (
        <div className="form-container">
            {
                label ? <label htmlFor={name}>{label}</label> : null
            }
            <Field as='textarea' name={name} id={name} {...rest} />
            <ErrorMessage name={name} component={FormErrorText} />
        </div>
    )
}

export default Textarea