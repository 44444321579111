import React, { useEffect } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { XR_Logo_Black } from "../assets";
import { ProfileUpdateToast } from "./Profile/ProfileUpdateToast";
import { LOCAL_STORAGE_KEY } from "../utils/constants";
import { userMenu } from "../utils/dropdownOptions";

const Dashboard = () => {
	const navigate = useNavigate();
	const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY)
		? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
		: localStorage.getItem(LOCAL_STORAGE_KEY);

	useEffect(() => {
		if (!isLoggedIn) {
			navigate("/");
		} else {
			navigate("/dashboard");
		}
	}, [navigate]);

	const logOut = () => {
		localStorage.setItem(LOCAL_STORAGE_KEY, "");
		navigate("/");
	};

	if (!isLoggedIn) return null;

	return (
		<>
			<ProfileUpdateToast
				empDataValidate={isLoggedIn.empDataValidate}
				empFirstName={isLoggedIn.empFirstName}
				empLastName={isLoggedIn.empLastName}
			/>
			<header>
				<nav className="navbar">
					<div className="logo">
						<img src={XR_Logo_Black} alt="XRealty Logo" />
					</div>
					<ul>
						{userMenu?.map((item) => {
							return (
								<li key={item.linkTo}>
									<NavLink to={item.linkTo}>{item.menuItemText}</NavLink>
								</li>
							);
						})}
					</ul>
					<ul>
						<li>
							<Link onClick={logOut}>Logout</Link>
						</li>
					</ul>
				</nav>
			</header>
			<div className="main">
				<section>
					<div className="main-wrapper">
						<div className="welcome-text">
							<svg>
								<text x="50%" y="50%" dy=".35em" textAnchor="middle">
									Welcome {isLoggedIn.empFirstName} {isLoggedIn.empLastName}
								</text>
							</svg>
						</div>
						{/* <p style={{ textAlign: "center", fontSize: "1.4rem" }}>
							Something will come soon here! 🎉
						</p> */}
					</div>
				</section>
			</div>
			{/* <footer>
                <div className="footer-text">
                    <p>XR Portal 2023 &copy; Made by XR Team</p>
                </div>
            </footer> */}
		</>
	);
};

export default Dashboard;
