import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { XR_Logo_Black } from '../../assets'
import FormikControl from "../../utils/Formik/FormikControl";
import { RESET_PWD } from "../../apiStrings/users"

const initialValues = {
    empXRealtyEmail: "",
    instructOnly: "true"
}

const validationSchema = Yup.object({
    empXRealtyEmail: Yup.string().matches(/^[A-Za-z0-9._%+-]+@xrealty\.ae$/, 'Invalid work email, Please enter a valid work email! (example@xrealty.ae)').required("Work email address required! (example@xrealty.ae)"),
})

function ForgotPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState("");

    useEffect(() => {

    }, [isLoading]);

    const onSubmit = async (values, { resetForm }) => {
        setIsLoading(true);

        try {
            const response = await fetch(RESET_PWD, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const res = await response.json();
            setErrMessage(res);

            if (res.status === 200) {
                setIsLoading(false);
                resetForm({ values: '' })
            } else {
                setIsLoading(false);
            }
        }
        catch (err) {
            console.error(err)
        }

        setIsLoading(false);
    }

    return (
        <div className="reset-password">
            <div className="auth-wrapper">
                <div className="auth-container">
                    <div className="auth-header">
                        <img src={XR_Logo_Black} alt="XRealty Portal" />

                        <h2>Reset password</h2>
                        <p>No worries, Enter the email associated with your account and we'll send an email with instructions to reset vour password.</p>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            formik => {
                                return (
                                    <Form>
                                        <>
                                            <FormikControl control="input" type="email" name="empXRealtyEmail" placeholder="XR Email Address" />

                                            {
                                                errMessage.status == 200 ? <p className="errMsgFinal">{errMessage.message}</p> : <p className="errMsg">{errMessage.message}</p>
                                            }

                                            <button type="submit">
                                                {
                                                    isLoading
                                                        ? <img src="https://xrealty.sirv.com/Images/Loader/Infinity-loader-white.svg" alt="Infinity" className="loading" />
                                                        : <p style={{ padding: "16px 0" }}>Send Instructions</p>
                                                }
                                            </button>
                                        </>
                                    </Form>
                                )
                            }
                        }
                    </Formik>

                    <div className="extra-info">
                        <Link to="/">
                            Remember your password? <span style={{ fontWeight: "600", textDecoration: "underline" }}>Back to Login</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;