import React, { useState, useEffect } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { XR_Logo_Black, previewImg } from "../../assets";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";
import { ProfileUpdateToast } from "../Profile/ProfileUpdateToast";
import { BsFillPatchCheckFill, BsLayersFill } from "react-icons/bs";
import { IoBedSharp, IoCalendarNumber } from "react-icons/io5";
import { ImPriceTags } from "react-icons/im";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { featuresListOptions, userMenu } from "../../utils/dropdownOptions";

const animatedComponents = makeAnimated();

const BrochureGenerator = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    : localStorage.getItem(LOCAL_STORAGE_KEY);

  const [selectedAgentData, setSelectedAgentData] = useState([]);
  const [inputData, setInputData] = useState({
    videoURL: "",
    projectName: "",
    startingPrice: "",
    unitType: "",
    startingArea: "",
    handover: "",
    projectBrief: "",
    propDesc: "",
    propLocation: "",
    propDeveloper: "",
    propPayment: "",
    locDesc: "",
  });
  const [imgData, setImgData] = useState([]);
  const [selectedFeaturesData, setSelectedFeaturesData] = useState({
    optionSelected: null,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }

    fetchAgentData();
  }, [navigate]);

  // Agents Data
  const fetchAgentData = async () => {
    try {
      const response = await fetch("https://xr.xrealty.ae/api/get_user.php", {
        method: "POST",
        body: JSON.stringify({
          workEmailXr: isLoggedIn.empXRealtyEmail,
          // workEmailXr: "umair@xrealty.ae",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();

      if (res.status === 200) {
        setSelectedAgentData(res.data);
      } else {
        setSelectedAgentData(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeAgent = (event) => {
    const value = event.target.value;

    const agentFound = agentData.find((item) => {
      return item.id === value;
    });

    setSelectedAgentData(agentFound);
  };

  const handleDataChange = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });
  };

  const handleFeaturesChange = (selected) => {
    setSelectedFeaturesData({ optionSelected: selected });
    // console.log(selectedFeaturesData);
  };

  const handleImageChange = (event) => {
    // setImgData(URL.createObjectURL(event.target.files[0]));
    setImgData(event.target.files);
  };

  const validate = () => {
    return (
      inputData.projectName.length &&
      inputData.startingPrice.length &&
      inputData.unitType.length &&
      inputData.startingArea.length &&
      inputData.handover.length &&
      inputData.projectBrief.length &&
      inputData.propDesc.length &&
      inputData.propLocation.length &&
      inputData.propDeveloper.length &&
      inputData.propPayment.length &&
      inputData.locDesc.length
    );
    // return 1;
  };

  const generatePDFN = () => {
    // html2canvas(document.querySelector(".pdf-generate-new")).then((canvas) => {
    //   const base64image = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF({
    //     orientation: "p",
    //     unit: "in",
    //     format: [canvas.width * 0.0104166667, canvas.height * 0.0104166667],
    //   });
    //   pdf.addImage(
    //     base64image,
    //     "JPEG",
    //     0,
    //     0,
    //     canvas.width * 0.0104166667,
    //     canvas.height * 0.0104166667
    //   );
    //   pdf.save("attributes-results.pdf");
    // });
    window.print();
  };

  const logOut = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "");
    navigate("/");
  };
  if (!isLoggedIn) return null;

  return (
    <>
      <ProfileUpdateToast
        empDataValidate={isLoggedIn.empDataValidate}
        empFirstName={isLoggedIn.empFirstName}
        empLastName={isLoggedIn.empLastName}
      />
      <header className="noprint">
        <nav className="navbar">
          <div className="logo">
            <img src={XR_Logo_Black} alt="XRealty Logo" />
          </div>
          <ul>
            {userMenu?.map((item) => {
              return (
                <li key={item.linkTo}>
                  <NavLink to={item.linkTo}>{item.menuItemText}</NavLink>
                </li>
              );
            })}
          </ul>
          <ul>
            <li>
              <Link onClick={logOut}>Logout</Link>
            </li>
          </ul>
        </nav>
      </header>

      <div className="noprint">
        <div className="wrapper-pdf">
          <div className="pdf-form-data-drop">
            <h2 style={{ color: "red" }}>ALL FIELDS ARE MANDATORY</h2>
            <h2>YouTube Video Link</h2>
            {/* <select id="agentData" className="hk-dropdown" onChange={onChangeAgent} defaultValue="Select Your Name">
                            <option value="Select Your Name" disabled>Select Your Name</option>
                            {agentData?.map(item => (
                                <option value={item.id} key={item.id}>{item.agent_name}</option>
                            ))}
                        </select> */}
            <input
              type="text"
              name="videoURL"
              className="hk-dropdown"
              placeholder="Add video link if you want to add it"
              onChange={handleDataChange}
            />
            <input
              type="hidden"
              name="agentData"
              className="hk-dropdown"
              value={
                selectedAgentData.agent_name
                  ? selectedAgentData.agent_name
                  : "Xperience Realty"
              }
              readOnly
            />

            <h2>Project Name</h2>
            <input
              type="text"
              name="projectName"
              className="hk-dropdown"
              placeholder="Enter Project Name"
              onChange={handleDataChange}
            />
          </div>

          <div className="pdf-form-data-drop">
            <h2>Starting Price</h2>
            <input
              type="text"
              name="startingPrice"
              className="hk-dropdown"
              placeholder="Enter Starting Price"
              onChange={handleDataChange}
            />

            <h2>
              Unit Type <span style={{ color: "red" }}>(1-5)</span>
            </h2>
            <input
              type="text"
              name="unitType"
              className="hk-dropdown"
              placeholder="Enter Unit Type (1-5)"
              onChange={handleDataChange}
            />
          </div>

          <div className="pdf-form-data-drop">
            <h2>
              Starting Area <span style={{ color: "red" }}>(sq. ft.)</span>
            </h2>
            <input
              type="text"
              name="startingArea"
              className="hk-dropdown"
              placeholder="Starting Area (sq. ft.)"
              onChange={handleDataChange}
            />
            <h2>
              Handover <span style={{ color: "red" }}>(Q4-2027)</span>
            </h2>
            <input
              type="text"
              name="handover"
              className="hk-dropdown"
              placeholder="Handover (Q4-2027)"
              onChange={handleDataChange}
            />
          </div>

          <div className="pdf-form-data-drop">
            <h2>Project Location</h2>
            <input
              type="text"
              name="propLocation"
              className="hk-dropdown"
              placeholder="Enter Project Location"
              onChange={handleDataChange}
            />
            <h2>Developer Name</h2>
            <input
              type="text"
              name="propDeveloper"
              className="hk-dropdown"
              placeholder="Enter Developer Name"
              onChange={handleDataChange}
            />
          </div>

          <div className="pdf-form-data-drop">
            <h2>
              Project Brief{" "}
              <span style={{ color: "red" }}>(720 Character Max)</span>
            </h2>
            <textarea
              id="projectBrief"
              name="projectBrief"
              className="textareaPdfData"
              onChange={handleDataChange}
              maxLength={720}
            ></textarea>
          </div>
        </div>

        <div className="wrapper-pdf">
          <div className="pdf-form-data-drop">
            <h2>
              Property Description{" "}
              <span style={{ color: "red" }}>(1500 Character Max)</span>
            </h2>
            <textarea
              id="propDesc"
              name="propDesc"
              className="textareaPdfData"
              onChange={handleDataChange}
              maxLength={1500}
            ></textarea>
          </div>
          <div className="pdf-form-data-drop">
            <h2>
              Location Description{" "}
              <span style={{ color: "red" }}>(720 Character Max)</span>
            </h2>
            <textarea
              id="locDesc"
              name="locDesc"
              className="textareaPdfData"
              onChange={handleDataChange}
              maxLength={720}
            ></textarea>
          </div>

          <div className="pdf-form-data-drop">
            <h2>
              Payment Plan <span style={{ color: "red" }}>(90/10)</span>
            </h2>
            <input
              type="text"
              name="propPayment"
              className="hk-dropdown-second"
              placeholder="Payment Plan (90/10)"
              onChange={handleDataChange}
            />

            <h2 className={imgData?.length > 9 ? "" : "error-text"}>
              Images (Select 12 Images Minimum)
            </h2>
            <input
              type="file"
              multiple
              name="mainImage"
              id="mainImage"
              className="hk-dropdown-second"
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div className="wrapper-pdf">
          <div className="pdf-form-data-drop">
            <h2>
              Property Features/Amenities{" "}
              <span style={{ color: "red" }}>(30 Max)</span>
            </h2>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              defaultValue={[]}
              isMulti
              options={featuresListOptions}
              onChange={handleFeaturesChange}
              name="propFeatures"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "#e9e9e9",
                },
              })}
            />

            <button
              onClick={generatePDFN}
              className="pdf-btn"
              //   disabled={!validate()}
            >
              Generate PDF
            </button>
          </div>
        </div>
      </div>

      <div className="pdf-generate-new" style={{ maxWidth: "210mm" }}>
        <div
          style={{
            maxWidth: "210mm",
            padding: "1.5rem 2rem",
            color: "#222222",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #222",
              paddingBottom: "1rem",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>
              {inputData.projectName ? inputData.projectName : "[PROJECT_NAME]"}
            </h1>
            <img
              src={XR_Logo_Black}
              alt="xr logo"
              style={{ width: "3rem", height: "3rem" }}
            />
          </div>

          {inputData.videoURL ? (
            <a href={inputData.videoURL}>
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                  marginTop: "1.5rem",
                }}
                className="videoImg"
              >
                <img
                  src={
                    imgData?.length > 0
                      ? URL.createObjectURL(imgData[0])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 0
                      ? imgData[0].lastModified + imgData[0].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            </a>
          ) : (
            <div
              style={{
                aspectRatio: "16/9",
                width: "100%",
                marginTop: "1.5rem",
              }}
            >
              <img
                src={
                  imgData?.length > 0
                    ? URL.createObjectURL(imgData[0])
                    : previewImg
                }
                alt={
                  imgData?.length > 0
                    ? imgData[0].lastModified + imgData[0].size
                    : ""
                }
                style={{ width: "100%", height: "100%", objectFit: "fill" }}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1.5rem 0",
              borderBottom: "1px solid #dddddd",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.6rem",
                }}
              >
                <ImPriceTags style={{ width: "1.3rem", height: "1.3rem" }} />
                <p style={{ fontSize: "1.3rem", fontWeight: "700" }}>
                  {inputData.startingPrice
                    ? inputData.startingPrice
                    : "[XXXXXXX]"}{" "}
                  AED
                </p>
              </div>
              <p style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                Starting Price From
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.6rem",
                }}
              >
                <IoBedSharp style={{ width: "1.3rem", height: "1.3rem" }} />
                <p style={{ fontSize: "1.3rem", fontWeight: "700" }}>
                  {inputData.unitType ? inputData.unitType : "[X-X]"} BR
                </p>
              </div>
              <p style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                Available Units
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.6rem",
                }}
              >
                <BsLayersFill style={{ width: "1.3rem", height: "1.3rem" }} />
                <p style={{ fontSize: "1.3rem", fontWeight: "700" }}>
                  {inputData.startingArea ? inputData.startingArea : "[XXXX]"}
                </p>
              </div>
              <p style={{ fontSize: "1.2rem", fontWeight: "400" }}>
                Area From (sq. ft.)
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.4rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.6rem",
                }}
              >
                <IoCalendarNumber
                  style={{ width: "1.3rem", height: "1.3rem" }}
                />
                <p style={{ fontSize: "1.3rem", fontWeight: "700" }}>
                  {inputData.handover ? inputData.handover : "[XX-XXXX]"}
                </p>
              </div>
              <p style={{ fontSize: "1.2rem", fontWeight: "400" }}>Handover</p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1.5rem 0",
            }}
          >
            <p style={{ fontSize: "1.1rem" }}>
              {inputData.projectBrief
                ? inputData.projectBrief
                : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div
              style={{
                aspectRatio: "16/9",
                width: "100%",
              }}
            >
              <img
                src={
                  imgData?.length > 1
                    ? URL.createObjectURL(imgData[1])
                    : previewImg
                }
                alt={
                  imgData?.length > 1
                    ? imgData[1].lastModified + imgData[1].size
                    : ""
                }
                style={{ width: "100%", height: "100%", objectFit: "fill" }}
              />
            </div>

            <div
              style={{
                aspectRatio: "16/9",
                width: "100%",
              }}
            >
              <img
                src={
                  imgData?.length > 2
                    ? URL.createObjectURL(imgData[2])
                    : previewImg
                }
                alt={
                  imgData?.length > 2
                    ? imgData[2].lastModified + imgData[2].size
                    : ""
                }
                style={{ width: "100%", height: "100%", objectFit: "fill" }}
              />
            </div>
          </div>

          <div
            style={{ aspectRatio: "16/9", width: "100%", marginTop: "1.5rem" }}
          >
            <img
              src={
                imgData?.length > 3
                  ? URL.createObjectURL(imgData[3])
                  : previewImg
              }
              alt={
                imgData?.length > 3
                  ? imgData[3].lastModified + imgData[3].size
                  : ""
              }
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              borderBottom: "1px solid #ddd",
              padding: "1.5rem 0 0.8rem 0",
              width: "100%",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>Features</h1>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              alignItems: "self-start",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            {selectedFeaturesData?.optionSelected?.slice(0, 30).map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "0.8rem",
                    width: "100%",
                  }}
                  key={item.value}
                >
                  <BsFillPatchCheckFill
                    style={{ width: "1.2rem", height: "1.2rem" }}
                  />
                  <p style={{ fontSize: "1.2rem" }}>{item.label}</p>
                </div>
              );
            })}
          </div>

          <div
            style={{ aspectRatio: "16/9", width: "100%", marginTop: "1.5rem" }}
          >
            <img
              src={
                imgData?.length > 4
                  ? URL.createObjectURL(imgData[4])
                  : previewImg
              }
              alt={
                imgData?.length > 4
                  ? imgData[4].lastModified + imgData[4].size
                  : ""
              }
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              borderBottom: "1px solid #ddd",
              padding: "1.5rem 0 0.8rem 0",
              width: "100%",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>Property Information</h1>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1.5rem 0",
            }}
          >
            <p style={{ fontSize: "1.1rem" }}>
              {inputData.propDesc
                ? inputData.propDesc
                : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
            </p>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              alignItems: "center",
              padding: "1.5rem 0",
              gap: "0 2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "2rem",
                borderTop: "1px solid #ddd",
                borderBottom: "1px solid #ddd",
                padding: "1rem 0",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "400",
                  color: "#444",
                  width: "40%",
                }}
              >
                Location
              </p>
              <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>
                {inputData.propLocation
                  ? inputData.propLocation
                  : "[XXX XXX XXXX]"}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "2rem",
                borderTop: "1px solid #ddd",
                borderBottom: "1px solid #ddd",
                padding: "1rem 0",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "400",
                  color: "#444",
                  width: "40%",
                }}
              >
                Developer
              </p>
              <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>
                {inputData.propDeveloper
                  ? inputData.propDeveloper
                  : "[XXXX XXXX]"}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "2rem",
                borderBottom: "1px solid #ddd",
                padding: "1rem 0",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "400",
                  color: "#444",
                  width: "40%",
                }}
              >
                Area From
              </p>
              <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>
                {inputData.startingArea ? inputData.startingArea : "[XXXXX]"}{" "}
                sq. ft.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "2rem",
                borderBottom: "1px solid #ddd",
                padding: "1rem 0",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "400",
                  color: "#444",
                  width: "40%",
                }}
              >
                Payment Plan
              </p>
              <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>
                {inputData.propPayment ? inputData.propPayment : "[XX/XX]"}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "2rem",
                borderBottom: "1px solid #ddd",
                padding: "1rem 0",
                width: "100%",
              }}
            >
              <p
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "400",
                  color: "#444",
                  width: "40%",
                }}
              >
                Handover
              </p>
              <p style={{ fontSize: "1.3rem", fontWeight: "600" }}>
                {inputData.handover ? inputData.handover : "[XX-XXXX]"}
              </p>
            </div>
          </div>

          <div
            style={{ aspectRatio: "16/9", width: "100%", marginTop: "1.5rem" }}
          >
            <img
              src={
                imgData?.length > 5
                  ? URL.createObjectURL(imgData[5])
                  : previewImg
              }
              alt={
                imgData?.length > 5
                  ? imgData[5].lastModified + imgData[5].size
                  : ""
              }
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              borderBottom: "1px solid #ddd",
              padding: "1.5rem 0 0.8rem 0",
              width: "100%",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>Location Information</h1>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1.5rem 0",
            }}
          >
            <p style={{ fontSize: "1.1rem" }}>
              {inputData.locDesc
                ? inputData.locDesc
                : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."}
            </p>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {imgData?.length > 6 ? (
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                }}
              >
                <img
                  src={
                    imgData?.length > 6
                      ? URL.createObjectURL(imgData[6])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 6
                      ? imgData[6].lastModified + imgData[6].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            ) : null}

            {imgData?.length > 7 ? (
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                }}
              >
                <img
                  src={
                    imgData?.length > 7
                      ? URL.createObjectURL(imgData[7])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 7
                      ? imgData[7].lastModified + imgData[7].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            ) : null}

            {imgData?.length > 8 ? (
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                }}
              >
                <img
                  src={
                    imgData?.length > 8
                      ? URL.createObjectURL(imgData[8])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 8
                      ? imgData[8].lastModified + imgData[8].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            ) : null}

            {imgData?.length > 9 ? (
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                }}
              >
                <img
                  src={
                    imgData?.length > 9
                      ? URL.createObjectURL(imgData[9])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 9
                      ? imgData[9].lastModified + imgData[9].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            ) : null}

            {imgData?.length > 10 ? (
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                }}
              >
                <img
                  src={
                    imgData?.length > 10
                      ? URL.createObjectURL(imgData[10])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 10
                      ? imgData[10].lastModified + imgData[10].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            ) : null}

            {imgData?.length > 11 ? (
              <div
                style={{
                  aspectRatio: "16/9",
                  width: "100%",
                }}
              >
                <img
                  src={
                    imgData?.length > 11
                      ? URL.createObjectURL(imgData[11])
                      : previewImg
                  }
                  alt={
                    imgData?.length > 11
                      ? imgData[11].lastModified + imgData[11].size
                      : ""
                  }
                  style={{ width: "100%", height: "100%", objectFit: "fill" }}
                />
              </div>
            ) : null}
          </div>

          {/* agent profile */}
          <div
            style={{ marginTop: "1.5rem" }}
            className="agent-preview-hk"
            dangerouslySetInnerHTML={{
              __html: selectedAgentData.agent_profile
                ? selectedAgentData.agent_profile
                : "",
            }}
          />

          {/* Footer */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "1px solid #222",
              paddingTop: "1rem",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "0.5rem",
                }}
              >
                <p style={{ fontSize: "1rem", fontWeight: "600" }}>
                  <a
                    href={
                      selectedAgentData.agent_name
                        ? selectedAgentData.agent_profile_url
                        : "https://xrealty.ae/?utm_source=brochure&utm_medium=banner&utm_campaign=brochure_banner"
                    }
                    style={{ textDecoration: "none", color: "#222222" }}
                  >
                    {selectedAgentData.agent_name
                      ? selectedAgentData.agent_name
                      : "Xperience Realty"}
                  </a>
                </p>
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  &nbsp; | &nbsp;
                </span>
                <p style={{ fontSize: "1rem", fontWeight: "600" }}>
                  <a
                    href={
                      selectedAgentData.agent_mobile
                        ? `tel:` + selectedAgentData.agent_mobile
                        : "tel:+971 56 408 0888"
                    }
                    style={{ textDecoration: "none", color: "#222222" }}
                  >
                    {selectedAgentData.agent_mobile
                      ? selectedAgentData.agent_mobile
                      : "+971 56 408 0888"}
                  </a>
                </p>
                <span style={{ fontSize: "1rem", fontWeight: "600" }}>
                  &nbsp; | &nbsp;
                </span>
                <p style={{ fontSize: "1rem", fontWeight: "600" }}>
                  <a
                    href={
                      selectedAgentData.agent_email
                        ? `mailto:` + selectedAgentData.agent_email
                        : "mailto:info@xrealty.ae"
                    }
                    style={{ textDecoration: "none", color: "#222222" }}
                  >
                    {selectedAgentData.agent_email
                      ? selectedAgentData.agent_email
                      : "info@xrealty.ae"}
                  </a>
                </p>
              </div>
              <p style={{ fontSize: "1rem", fontWeight: "600" }}>
                Office 301 & 302, Park Heights Square 1, Dubai Hills Estate,
                Dubai, UAE
              </p>
            </div>
            <img
              src={XR_Logo_Black}
              alt="xr logo"
              style={{ width: "3rem", height: "3rem" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BrochureGenerator;
