import FormikControl from '../../utils/Formik/FormikControl'

const PreviousEmployment = () => {

    return (
        <>
            <h2 style={{ margin: "1rem 0" }}>Previous Employer Details</h2>
            <div className="main-form-container">
                <FormikControl control="input" type="text" label="Company Name" name="empPrevComName" placeholder="Company Name" />
                <FormikControl control="input" type="text" label="Designation" name="empPrevDesig" placeholder="Designation" />
                <FormikControl control="input" type="tel" label="Office Mobile" name="empPrevOfficeMobile" placeholder="Office Mobile" />
                <FormikControl control="input" type="text" label="Office Address" name="empPrevOfficeAddress" placeholder="Office Address" />
            </div>
        </>
    )
}

export default PreviousEmployment