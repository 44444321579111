import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export const ProfileUpdateToast = ({
	empDataValidate,
	empFirstName,
	empLastName,
}) => {
	useEffect(() => {
		notify();
	}, []);

	const Msg = () => (
		<div>
			Hello,{" "}
			<strong>
				{empFirstName} {empLastName}
			</strong>{" "}
			👋🏻
			<br />
			<br />
			Please update your profile with valid data using profile menu.
			<br />
			<sub>- XR Team 🎉</sub>
		</div>
	);

	const notify = () =>
		toast.error(<Msg empFirstName={empFirstName} empLastName={empLastName} />, {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			limit: "1",
		});

	return <>{empDataValidate === "0" ? <ToastContainer /> : null}</>;
};
