import React, { useState, useEffect, useRef } from "react";
import { toPng } from "html-to-image";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { ProfileUpdateToast } from "../Profile/ProfileUpdateToast";
import { XR_Logo_Black } from "../../assets";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";
import { userMenu } from "../../utils/dropdownOptions";
import { GET_USER, GET_PROJECT } from "../../apiStrings/users";

const EDM = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
    : localStorage.getItem(LOCAL_STORAGE_KEY);
  const [isLoading, setIsLoading] = useState("Download as a Image");
  const [disabled, setDisabled] = useState(true);

  const [selectedAgentData, setSelectedAgentData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [selectedProjectData, setSelectedProjectData] = useState([]);

  const elementRef = useRef(null);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }

    fetchAgentData();
    fetchProjectData();
  }, [navigate]);

  console.log("Hello");

  // Agents Data
  const fetchAgentData = async () => {
    try {
      const response = await fetch(GET_USER, {
        method: "POST",
        body: JSON.stringify({
          workEmailXr: isLoggedIn.empXRealtyEmail,
          //   workEmailXr: "shumaila.k@xrealty.ae",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();

      console.log(res);

      if (res.status === 200) {
        setSelectedAgentData(res.data);
      } else {
        setSelectedAgentData(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Projects Data
  const fetchProjectData = () => {
    fetch(GET_PROJECT)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setProjectData(data.data);
      });
  };

  const onChangeProject = (event) => {
    const value = event.target.value;

    const projectFound = projectData.find((item) => {
      return item.id === value;
    });

    setSelectedProjectData(projectFound);
    setDisabled(false);
  };

  const copyToClipboard = () => {
    const finalClipboard =
      selectedProjectData.edm_html +
      selectedAgentData.agent_profile +
      selectedProjectData.footer_html;
    navigator.clipboard.writeText(finalClipboard);
  };

  const htmlToImageConvert = () => {
    setIsLoading("Downloading...");

    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        setIsLoading("Download as a Image");
        const link = document.createElement("a");
        link.download =
          selectedProjectData.project_name +
          " - " +
          isLoggedIn.empFirstName +
          " " +
          isLoggedIn.empLastName +
          " - Xperience Realty Real Estate - Dubai.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        setIsLoading("Download as a Image");
        console.log(err);
      });

    setIsLoading("Downloading...");
  };

  const logOut = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, "");
    navigate("/");
  };

  if (!isLoggedIn) return null;

  return (
    <>
      <ProfileUpdateToast
        empDataValidate={isLoggedIn.empDataValidate}
        empFirstName={isLoggedIn.empFirstName}
        empLastName={isLoggedIn.empLastName}
        className="noprint"
      />
      <header className="noprint">
        <nav className="navbar">
          <div className="logo">
            <img src={XR_Logo_Black} alt="XRealty Logo" />
          </div>
          <ul>
            {userMenu?.map((item) => {
              return (
                <li key={item.linkTo}>
                  <NavLink to={item.linkTo}>{item.menuItemText}</NavLink>
                </li>
              );
            })}
          </ul>
          <ul>
            <li>
              <Link onClick={logOut}>Logout</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="container-hk noprint">
        <div className="wrapper">
          <div className="form-container">
            <div className="form-data-drop">
              <h2>1. Select Project Name to Get EDM HTML Code</h2>
              <select
                id="projectData"
                className="hk-dropdown"
                onChange={onChangeProject}
                defaultValue="Select Project Name"
              >
                <option value="Select Project Name" disabled>
                  Select Your Name
                </option>
                {projectData?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.project_name}
                  </option>
                ))}
              </select>
              <br />
              <br />
            </div>

            <div className="output-html">
              <h2>2. Your HTML Code</h2>
              <textarea
                id="outputdata"
                name="outputdata"
                className="textareaOutputData"
                value={(
                  (selectedProjectData.edm_html
                    ? selectedProjectData.edm_html
                    : "") +
                  (selectedAgentData.agent_profile
                    ? selectedAgentData.agent_profile
                    : "") +
                  (selectedProjectData.footer_html
                    ? selectedProjectData.footer_html
                    : "")
                ).toString()}
                readOnly
              ></textarea>
            </div>

            <div className="edm-buttons">
              <button onClick={copyToClipboard} className="edm-button">
                Copy to Clipboard
              </button>
              <button
                onClick={htmlToImageConvert}
                className="edm-button"
                disabled={disabled}
              >
                {isLoading}
              </button>
            </div>
          </div>

          <div className="preview-wrapper">
            <h2>Preview Your Personal EDM</h2>
            <div className="preview-container">
              <div
                ref={elementRef}
                dangerouslySetInnerHTML={{
                  __html:
                    (selectedProjectData.edm_html
                      ? selectedProjectData.edm_html
                      : "") +
                    (selectedAgentData.agent_profile
                      ? selectedAgentData.agent_profile
                      : "") +
                    (selectedProjectData.footer_html
                      ? selectedProjectData.footer_html
                      : ""),
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="edm-pdf" dangerouslySetInnerHTML={
                {
                    __html: `<div class="document" role="article" aria-roledescription="email" aria-label="" lang="en" dir="ltr"
                    style="background-color:#fff8e3; line-height: 100%; font-size:medium; font-size:max(16px, 1rem);">
                    <div style="display: none; max-height: 0px; overflow: hidden;background-color:#fff8e3;"> </div>
                    <table width="100%" align="center" cellspacing="0" cellpadding="0" border="0">
                        <tr>
                            <td style="padding: 0 8px;background-color:#fff8e3;" background="" align="center" valign="top">
                                <table class="container" align="center" width="640" cellpadding="0" cellspacing="0" border="0"
                                    style="max-width: 640px;background-color:#fff8e3;">
                                    <tr>
                                        <td align="center"> </td>
                                    </tr>
                                </table>
                                <table width="640" class="wrapper" align="center" border="0" cellpadding="0" cellspacing="0"
                                    style=" max-width: 640px; border:1px solid #D1D3D3; border-radius:0px; border-collapse: separate!important; overflow: hidden; background-color:#fff8e3;">
                                    <tr>
                                        <td align="center">
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-4 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="width: 640px; min-width: 640px;background-color:#fff8e3;">
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td class="" align="center" valign="top"
                                                                                style="padding: 0 0px; line-height: 0;background-color:#fff8e3;">
                                                                                <a href="" target="_self"
                                                                                    style="text-decoration: none; pointer-events: none;background-color:#fff8e3;">
                                                                                    <img src="https://storage.mlcdn.com/account_image/454492/foKzbPOgMPKzZQHtfHiwDvRHRRqZaeKiG7aPq8tq.jpg"
                                                                                        class="img" width="640p"
                                                                                        style="display: block;background-color:#fff8e3;"
                                                                                        border="0"> </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-4 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="background-color:#fff8e3;width: 640px; min-width: 640px;">
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td class="" align="center" valign="top"
                                                                                style="background-color:#fff8e3;padding: 0 0px; line-height: 0;">
                                                                                <a href="" target="_self"
                                                                                    style="background-color:#fff8e3;text-decoration: none; pointer-events: none;">
                                                                                    <img src="https://storage.mlcdn.com/account_image/454492/xl3MONjBWzhDPOzmo1ipMoNUMyMmPEx4PhwnSZbq.jpg"
                                                                                        class="img" width="640p"
                                                                                        style="display: block;background-color:#fff8e3;"
                                                                                        border="0"> </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-4 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="width: 640px; min-width: 640px;background-color:#fff8e3;">
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td class="" align="center" valign="top"
                                                                                style="padding: 0 0px; line-height: 0;background-color:#fff8e3;">
                                                                                <a href="" target="_self"
                                                                                    style="text-decoration: none; pointer-events: none;background-color:#fff8e3;">
                                                                                    <img src="https://storage.mlcdn.com/account_image/454492/TGQp0QkQ06se5S2lx1H7jMHVyc3cBMD38KRKGYE2.jpg"
                                                                                        class="img" width="640p"
                                                                                        style="display: block;background-color:#fff8e3;"
                                                                                        border="0"> </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-4 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="width: 640px; min-width: 640px;background-color:#fff8e3;">
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td class="" align="center" valign="top"
                                                                                style="padding: 0 0px; line-height: 0;background-color:#fff8e3;">
                                                                                <a href="https://api.whatsapp.com/send?phone=971564080888&text=Hi%0AI%27m%20interested%20in%20your%20ad.%20Kindly%20share%20more%20information%20on%20this%20project."
                                                                                    target="_self"
                                                                                    style="text-decoration: none;background-color:#fff8e3;">
                                                                                    <img src="https://storage.mlcdn.com/account_image/454492/7d3BzHNTCneHzUFmMXhlhMxRGNv8pXa1Tm5qbMUl.jpg"
                                                                                        class="img" width="640p"
                                                                                        style="display: block;background-color:#fff8e3;"
                                                                                        border="0"> </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-4 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="width: 640px;background-color:#fff8e3; min-width: 640px;">
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td class="" align="center" valign="top"
                                                                                style="padding: 0 0px;background-color:#fff8e3; line-height: 0;">
                                                                                <a href="" target="_self"
                                                                                    style="text-decoration: none;background-color:#fff8e3; pointer-events: none;">
                                                                                    <img src="https://xrealty.sirv.com/Projects/Expo%20Valley%20EDM/ev-005.jpg"
                                                                                        class="img" width="640p"
                                                                                        style="display: block;background-color:#fff8e3;"
                                                                                        border="0"> </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-4 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="background-color:#fff8e3;width: 640px; min-width: 640px;">
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                                        <tr>
                                                                            <td class="" align="center" valign="top"
                                                                                style="padding: 0 0px; line-height: 0;background-color:#fff8e3;">
                                                                                <a href="" target="_self"
                                                                                    style="text-decoration: none; pointer-events: none;">
                                                                                    <img src="https://storage.mlcdn.com/account_image/454492/YL5T2yaSBePO9w66accyZAv8FR2R83tT3nwr2J56.jpg"
                                                                                        class="img" width="640p"
                                                                                        style="display: block;background-color:#fff8e3;"
                                                                                        border="0"> </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                
                                            <table class="ml-default" width="100%" bgcolor="" align="center" border="0" cellspacing="0"
                                                cellpadding="0">
                                                <tr>
                                                    <td style="background-color:#fff8e3;">
                                                        <table class="container ml-6 ml-default-border" width="640" bgcolor=""
                                                            align="center" border="0" cellspacing="0" cellpadding="0"
                                                            style="width: 640px; min-width: 640px; background-color:#fff8e3;">
                                                            <tr>
                                                                <td class="ml-default-border container" height="10"
                                                                    style="line-height: 10px; min-width: 640px;background-color:#fff8e3;">
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="row" style="padding: 0 50px;background-color:#fff8e3;">
                                                                    <table align="center" width="100%" border="0" cellspacing="0"
                                                                        cellpadding="0">
                                                                        <tr>
                                                                            <td>
                                                                                <table align="center" width="100%" border="0"
                                                                                    cellspacing="0" cellpadding="0">
                                                                                    <tr>
                                                                                        <td class="col" align="left" valign="middle"
                                                                                            width="40"> <img
                                                                                                src="https://storage.mlcdn.com/account_image/454492/x1GNunYQQv9R7Oai9Y0MaExN9VRQmXXqCdZ8rrIu.png"
                                                                                                border="0" alt="" width="40"
                                                                                                class="logo"
                                                                                                style="max-width: 40px; display: inline-block;">
                                                                                        </td>
                                                                                        <td class="col" width="40" height="30"
                                                                                            style="line-height: 30px;"></td>
                                                                                        <td class="col mobile-left" align="right"
                                                                                            valign="middle" width="250">
                                                                                            <table align="left" width="100%" border="0"
                                                                                                cellspacing="0" cellpadding="0">
                                                                                                <tr>
                                                                                                    <td class="col" align="left"
                                                                                                        valign="middle" width="40">
                                                                                                    </td>
                                                                                                    <td class="col" width="40"
                                                                                                        height="0"
                                                                                                        style="line-height: 0px;">
                                                                                                    </td>
                                                                                                    <td class="col mobile-left"
                                                                                                        align="right" valign="middle"
                                                                                                        width="250">
                                                                                                        <table class="**$class**"
                                                                                                            role="presentation"
                                                                                                            cellpadding="0"
                                                                                                            cellspacing="0" border="0">
                                                                                                            <tr>
                                                                                                                <td align="left"
                                                                                                                    valign="middle"
                                                                                                                    width="102"
                                                                                                                    ng-show="slink.link !=''"
                                                                                                                    style="padding: 0 0 0 0;">
                                                                                                                    <p
                                                                                                                        style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 150%; margin-bottom: 6px; display: inline-block;">
                                                                                                                        <span
                                                                                                                            class="ml-rte-link-wrapper"
                                                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                                                                href=""
                                                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                                                    class="ml-rte-link-wrapper"
                                                                                                                                    style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span></a></span>
                                                                                                                    </p>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            <table align="left" width="100%" border="0"
                                                                                                cellspacing="0" cellpadding="0">
                                                                                                <tr>
                                                                                                    <td class="col" align="left"
                                                                                                        valign="middle" width="40">
                                                                                                    </td>
                                                                                                    <td class="col" width="40"
                                                                                                        height="0"
                                                                                                        style="line-height: 0px;">
                                                                                                    </td>
                                                                                                    <td class="col mobile-left"
                                                                                                        align="right" valign="middle"
                                                                                                        width="250">
                                                                                                        <table class="**$class**"
                                                                                                            role="presentation"
                                                                                                            cellpadding="0"
                                                                                                            cellspacing="0" border="0">
                                                                                                            <tr>
                                                                                                                <td align="left"
                                                                                                                    valign="middle"
                                                                                                                    width="125"
                                                                                                                    ng-show="slink.link !=''"
                                                                                                                    style="padding: 0 0 0 0;">
                                                                                                                    <p
                                                                                                                        style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 150%; margin-bottom: 6px; display: inline-block;">
                                                                                                                        <span
                                                                                                                            class="ml-rte-link-wrapper"
                                                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                                                                href="https://xrealty.ae/"
                                                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                                                    class="ml-rte-link-wrapper"
                                                                                                                                    style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span>Contact
                                                                                                                                us</a></span>
                                                                                                                    </p>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            <table class="**$class**"
                                                                                                role="presentation" cellpadding="0"
                                                                                                cellspacing="0" border="0">
                                                                                                <tr>
                                                                                                    <td align="center" valign="middle"
                                                                                                        width="16"
                                                                                                        ng-show="slink.link !=''"
                                                                                                        style="padding: 0 5px;"> <a
                                                                                                            href="https://www.youtube.com/@xrealtyae"
                                                                                                            target="blank"
                                                                                                            style="text-decoration: none;">
                                                                                                            <img src="https://xrealty.sirv.com/Images/social-icons/youtube.png"
                                                                                                                width="16"
                                                                                                                alt="youtube"> </a>
                                                                                                    </td>
                                                                                                    <td align="center" valign="middle"
                                                                                                        width="16"
                                                                                                        ng-show="slink.link !=''"
                                                                                                        style="padding: 0 5px;"> <a
                                                                                                            href="https://www.facebook.com/XRealty.ae"
                                                                                                            target="blank"
                                                                                                            style="text-decoration: none;">
                                                                                                            <img src="https://xrealty.sirv.com/Images/social-icons/facebook.png"
                                                                                                                width="16"
                                                                                                                alt="facebook"> </a>
                                                                                                    </td>
                                                                                                    <td align="center" valign="middle"
                                                                                                        width="16"
                                                                                                        ng-show="slink.link !=''"
                                                                                                        style="padding: 0 5px;"> <a
                                                                                                            href="https://www.linkedin.com/company/xrealty-ae/"
                                                                                                            target="blank"
                                                                                                            style="text-decoration: none;">
                                                                                                            <img src="https://xrealty.sirv.com/Images/social-icons/linkedin.png"
                                                                                                                width="16"
                                                                                                                alt="linkedin"> </a>
                                                                                                    </td>
                                                                                                    <td align="center" valign="middle"
                                                                                                        width="16"
                                                                                                        ng-show="slink.link !=''"
                                                                                                        style="padding: 0 5px;"> <a
                                                                                                            href="https://www.instagram.com/xrealty.ae"
                                                                                                            target="blank"
                                                                                                            style="text-decoration: none;">
                                                                                                            <img src="https://xrealty.sirv.com/Images/social-icons/instagram.png"
                                                                                                                width="16"
                                                                                                                alt="instagram">
                                                                                                        </a> </td>
                                                                                                    <td align="center" valign="middle"
                                                                                                        width="16"
                                                                                                        ng-show="slink.link !=''"
                                                                                                        style="padding: 0 5px;"> <a
                                                                                                            href="https://twitter.com/xrealty_ae"
                                                                                                            target="blank"
                                                                                                            style="text-decoration: none;">
                                                                                                            <img src="https://xrealty.sirv.com/Images/social-icons/twitter.png"
                                                                                                                width="16"
                                                                                                                alt="twitter"> </a>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            <table align="left" width="100%" border="0"
                                                                                                cellspacing="0" cellpadding="0">
                                                                                                <tr>
                                                                                                    <td class="col" align="left"
                                                                                                        valign="middle" width="40">
                                                                                                    </td>
                                                                                                    <td class="col" width="40"
                                                                                                        height="25"
                                                                                                        style="line-height: 25px;">
                                                                                                    </td>
                                                                                                    <td class="col mobile-left"
                                                                                                        align="right" valign="middle"
                                                                                                        width="250">
                                                                                                        <table class="**$class**"
                                                                                                            role="presentation"
                                                                                                            cellpadding="0"
                                                                                                            cellspacing="0" border="0">
                                                                                                            <tr>
                                                                                                                <td align="left"
                                                                                                                    valign="middle"
                                                                                                                    width="125"
                                                                                                                    ng-show="slink.link !=''"
                                                                                                                    style="padding: 0 0 0 0;">
                                                                                                                    <p
                                                                                                                        style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 150%; margin-bottom: 0px; display: inline-block;">
                                                                                                                        <span
                                                                                                                            class="ml-rte-link-wrapper"
                                                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                                                                href="https://xrealty.ae/"
                                                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                                                    class="ml-rte-link-wrapper"
                                                                                                                                    style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span>www.xrealty.ae</a></span>
                                                                                                                    </p>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center" style="text-align: left!important;">
                                                                                <p
                                                                                    style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 100%; margin-bottom: 6px; display: inline-block;">
                                                                                    <span class="ml-rte-link-wrapper"
                                                                                        style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                            href="mailto:info@xrealty.ae"
                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                class="ml-rte-link-wrapper"
                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span>info@xrealty.ae</a></span>
                                                                                    |&nbsp;
                                                                                </p>
                                                                                <p
                                                                                    style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 100%; margin-bottom: 6px; display: inline-block;">
                                                                                    <span class="ml-rte-link-wrapper"
                                                                                        style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                            href="tel:+971564080888"
                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;">+971
                                                                                            56 408 0888</a></span>
                                                                                </p>
                                                                                <div>
                                                                                    <p
                                                                                        style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 100%; margin-bottom: 6px; display: inline-block;">
                                                                                        <span class="ml-rte-link-wrapper"
                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                                href="https://g.co/kgs/VP4pvz"
                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                    class="ml-rte-link-wrapper"
                                                                                                    style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span>Office
                                                                                                301 & 302, Park Heights Square
                                                                                                Building 1,</a></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 12px; line-height: 100%; margin-bottom: 6px; display: inline-block;">
                                                                                        <span class="ml-rte-link-wrapper"
                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                                href="https://g.co/kgs/VP4pvz"
                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                    class="ml-rte-link-wrapper"
                                                                                                    style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span>Dubai
                                                                                                Hills Estate. PO Box 65777, Dubai,
                                                                                                UAE</a></span>
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td height="40" style="line-height: 40px;"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center" style="text-align: center!important;">
                                                                                <p
                                                                                    style="font-family: 'Roboto', sans-serif; color: #000000; font-size: 10px; line-height: 150%; margin-bottom: 6px; display: inline-block;">
                                                                                    <span class="ml-rte-link-wrapper"
                                                                                        style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><a
                                                                                            href="https://xrealty.ae/"
                                                                                            style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"><span
                                                                                                class="ml-rte-link-wrapper"
                                                                                                style="color: #000000; font-weight: normal; font-style: normal; text-decoration: none;"></span>©
                                                                                            Copyright 2022. Xperience Realty. All
                                                                                            rights reserved.</a></span>
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20" style="line-height:20px;"></td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>`
                }
            } /> */}
    </>
  );
};

export default EDM;
