import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import { XR_Logo_Black } from '../../assets'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import FormikControl from "../../utils/Formik/FormikControl";
import { RESET_PWD } from "../../apiStrings/users"

const validationSchema = Yup.object({
    empPassword: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character').required('Password required!'),
    empConfirmPassword: Yup.string().oneOf([Yup.ref('empPassword'), null], 'Password must match').required('Confirm Password required!'),
})

function ChangeNewPassword() {
    const param = useParams();
    const { tokenId, emailId } = param;

    const initialValues = {
        empXRealtyEmail: emailId ? emailId : "",
        empPassword: "",
        empConfirmPassword: "",
        resetLinkToken: tokenId ? tokenId : "",
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfimPassword, setShowConfimPassword] = useState(false);

    const handlePasswordShowHide = () => {
        setShowPassword(!showPassword);
    }

    const handleConfimPasswordShowHide = () => {
        setShowConfimPassword(!showConfimPassword);
    }

    const onSubmit = async (values, { resetForm }) => {
        setShowPassword(false);
        setShowConfimPassword(false);
        setIsLoading(true);

        try {
            const response = await fetch(RESET_PWD, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const res = await response.json();
            setErrMessage(res);

            if (res.status === 200) {
                setIsLoading(false);
                resetForm({ values: '' })
            } else {
                setIsLoading(false);
            }
        }
        catch (err) {
            console.error(err)
        }

        setIsLoading(false);
    }

    return (
        <div className="reset-password">
            <div className="auth-wrapper">
                <div className="auth-container">
                    <div className="auth-header">
                        <img src={XR_Logo_Black} alt="XRealty Portal" />

                        <h2>Create new password</h2>
                        <p>Your new password must be different from previous used passwords.</p>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            formik => {
                                return (
                                    <Form>
                                        <>
                                            <div className="form-container">
                                                <div className="inner-form-container">
                                                    <FormikControl control="input" type={showPassword ? "text" : "password"} name="empPassword" placeholder="********" style={{ width: "310px" }} autoComplete="off" />
                                                    {
                                                        showPassword
                                                            ? <FaEyeSlash className="password-show-hide" onClick={handlePasswordShowHide} />
                                                            : <FaEye className="password-show-hide" onClick={handlePasswordShowHide} />
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-container">
                                                <div className="inner-form-container">
                                                    <FormikControl control="input" type={showConfimPassword ? "text" : "password"} name="empConfirmPassword" placeholder="Confirm Password" style={{ width: "310px" }} autoComplete="off" />
                                                    {
                                                        showConfimPassword
                                                            ? <FaEyeSlash className="password-show-hide" onClick={handleConfimPasswordShowHide} />
                                                            : <FaEye className="password-show-hide" onClick={handleConfimPasswordShowHide} />
                                                    }
                                                </div>
                                            </div>

                                            {
                                                errMessage.status == 200 ? <p className="errMsgFinal">{errMessage.message}</p> : <p className="errMsg">{errMessage.message}</p>
                                            }

                                            <button type="submit">
                                                {
                                                    isLoading
                                                        ? <img src="https://xrealty.sirv.com/Images/Loader/Infinity-loader-white.svg" alt="Infinity" className="loading" />
                                                        : <p style={{ padding: "16px 0" }}>Reset password</p>
                                                }
                                            </button>
                                        </>
                                    </Form>
                                )
                            }
                        }
                    </Formik>

                    <div className="extra-info">
                        <Link to="/">
                            Remember your password? <span style={{ fontWeight: "600", textDecoration: "underline" }}>Back to Login</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeNewPassword;