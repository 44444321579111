import FormikControl from '../../utils/Formik/FormikControl';
import { visaStatusOptions, empDepartmentOptions } from '../../utils/dropdownOptions';

const EmployeeStatus = () => {

    return (
        <>
            <h2 style={{ margin: "1rem 0" }}>XR Employee Details</h2>

            <div className="main-form-container">
                <FormikControl control="input" type="date" label="Date of Employment" name="empDOE" placeholder="Date of Employment" />
                <FormikControl control="input" type="text" label="Job Title" name="empJobTitle" placeholder="Job Title" />
                <FormikControl control="select" label="Visa Status" name="empVisaStatus" placeholder="Visa Status" options={visaStatusOptions} />
                <FormikControl control="input" type="text" label="Emirates ID Number" name="empEIDNo" placeholder="Emirates ID Number" />
                <FormikControl control="input" type="date" label="Emirates ID Issue Date" name="empEIDIssueDate" placeholder="Emirates ID Issue Date" />
                <FormikControl control="input" type="date" label="Emirates ID Expiry Date" name="empEIDExpiryDate" placeholder="Emirates ID Expiry Date" />
                <FormikControl control="input" type="number" label="Salary (AED)" name="empSalary" placeholder="Salary (AED)" />
                <FormikControl control="input" type="number" label="Commission Percentage (%)" name="empCommPer" placeholder="Commission Percentage (%)" />
                <FormikControl control="select" label="Department" name="empDepartment" placeholder="Department" options={empDepartmentOptions} />
                <FormikControl control="select" label="Do you have a driver's license?" name="empDriverLicense" placeholder="Do you have a driver's license?"
                    options={[
                        { key: "Do you have a driver's license?", value: '' },
                        { key: 'Yes', value: 'Yes' },
                        { key: 'No', value: 'No' },
                    ]}
                />
                <FormikControl control="input" type="text" label="Driver's License Number" name="empDriverLicNo" placeholder="Driver's License Number" />
            </div>
        </>
    )
}

export default EmployeeStatus