import FormikControl from "../../utils/Formik/FormikControl";
import {
	religionOptions,
	maritalOptions,
	genderOptions,
	bloodGroupOptions,
	stateUAEOptions,
} from "../../utils/dropdownOptions";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

const PersonalInformation = () => {
	const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY)
		? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
		: localStorage.getItem(LOCAL_STORAGE_KEY);

	return (
		<>
			<h2 style={{ marginBottom: "1rem" }}>Personal Information</h2>
			<div className="main-form-container">
				<FormikControl
					control="input"
					type="text"
					label="First Name"
					name="empFirstName"
					placeholder="First Name"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Middle Name"
					name="empMiddleName"
					placeholder="Middle Name"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Last Name"
					name="empLastName"
					placeholder="Last Name"
					readOnly
				/>
				<FormikControl
					control="input"
					type="date"
					label="Date of Birth"
					name="empDOB"
					placeholder="Date of Birth"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Passport Number"
					name="empPassportNo"
					placeholder="Passport Number"
				/>
				<FormikControl
					control="input"
					type="date"
					label="Passport - Date of Issue"
					name="empPassportDOI"
					placeholder="Passport - Date of Issue"
				/>
				<FormikControl
					control="input"
					type="date"
					label="Passport - Date of Expiry"
					name="empPassportDOE"
					placeholder="Passport - Date of Expiry"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Citizenship"
					name="empCitizenship"
					placeholder="Citizenship"
				/>
				<FormikControl
					control="select"
					label="Religion"
					name="empReligion"
					placeholder="Religion"
					options={religionOptions}
				/>
				<FormikControl
					control="select"
					label="Marital Status"
					name="empMarital"
					placeholder="Marital Status"
					options={maritalOptions}
				/>
				<FormikControl
					control="select"
					label="Gender"
					name="empGender"
					placeholder="Gender"
					options={genderOptions}
				/>
				<FormikControl
					control="input"
					type="text"
					label="Spoken Languages"
					name="empLangSpoken"
					placeholder="Spoken Languages"
				/>
				<FormikControl
					control="select"
					label="Blood Group"
					name="empBloodGroup"
					placeholder="Blood Group"
					options={bloodGroupOptions}
				/>
				<FormikControl
					control="input"
					type="number"
					label="Total Experience (In Years)"
					name="empTotalExp"
					placeholder="Total Experience (In Years)"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Place of Birth"
					name="empPOB"
					placeholder="Place of Birth"
				/>

				<FormikControl
					control="input"
					type="text"
					label="Address (U.A.E.)"
					name="empAddressUAE"
					placeholder="Address (U.A.E.)"
				/>
				<FormikControl
					control="select"
					label="State/Province (U.A.E.)"
					name="empStateUAE"
					placeholder="State/Province (U.A.E.)"
					options={stateUAEOptions}
				/>
				<FormikControl
					control="input"
					type="text"
					label="Postal Code (U.A.E.)"
					name="empPostalCodeUAE"
					placeholder="Postal Code (U.A.E.)"
				/>

				<FormikControl
					control="input"
					type="text"
					label="Address (Hometown)"
					name="empAddressHT"
					placeholder="Address (Hometown)"
				/>
				<FormikControl
					control="input"
					type="text"
					label="State/Province (Hometown)"
					name="empStateHT"
					placeholder="State/Province (Hometown)"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Postal Code (Hometown)"
					name="empPostalCodeHT"
					placeholder="Postal Code (Hometown)"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Country (Hometown)"
					name="empCountryHT"
					placeholder="Country (Hometown)"
				/>
				<FormikControl
					control="input"
					type="tel"
					label="Home Phone"
					name="empHomePhone"
					placeholder="Home Phone"
					readOnly
				/>
				<FormikControl
					control="input"
					type="tel"
					label="Cell Phone"
					name="empCellPhone"
					placeholder="Cell Phone"
				/>
				<FormikControl
					control="input"
					type="email"
					label="Personal Email"
					name="empPersonalEmail"
					placeholder="Personal Email"
				/>
				<FormikControl
					control="input"
					type="email"
					label="XRealty Email"
					name="empXRealtyEmail"
					placeholder="XRealty Email"
					readOnly
				/>
				<FormikControl
					control="input"
					type="text"
					label="Father's Name"
					name="empFatherName"
					placeholder="Father's Name"
				/>
				<FormikControl
					control="input"
					type="text"
					label="Mother's Name"
					name="empMotherName"
					placeholder="Mother's Name"
				/>
			</div>
		</>
	);
};

export default PersonalInformation;
