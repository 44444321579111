import FormikControl from '../../utils/Formik/FormikControl'

const EmergencyContact = () => {

    return (
        <>
            <h2 style={{ margin: "1rem 0" }}>Emergency Contact</h2>
            <div className="main-form-container">
                <FormikControl control="input" type="text" label="Name" name="empEmgName" placeholder="Name" />
                <FormikControl control="input" type="text" label="Address" name="empEmgAddress" placeholder="Address" />
                <FormikControl control="input" type="text" label="State/Province" name="empEmgState" placeholder="State/Province" />
                <FormikControl control="input" type="text" label="ZIP/Postal" name="empEmgPostal" placeholder="ZIP/Postal" />
                <FormikControl control="input" type="email" label="Email Address" name="empEmgEmail" placeholder="Email Address" />
                <FormikControl control="input" type="tel" label="Work Phone" name="empEmgWorkPhone" placeholder="Work Phone" />
                <FormikControl control="input" type="tel" label="Cell Phone" name="empEmgCellPhone" placeholder="Cell Phone" />
                <FormikControl control="input" type="text" label="Relationship" name="empEmgRelation" placeholder="Relationship" />
            </div>
        </>
    )
}

export default EmergencyContact