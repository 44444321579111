import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormErrorText from "../../utils/Formik/FormErrorText";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { XR_Logo_Black } from "../../assets";
import { REGISTER } from "../../apiStrings/users";

const initialValues = {
	empFirstName: "",
	empLastName: "",
	empHomePhone: "",
	empXRealtyEmail: "",
	empPassword: "",
	empConfirmPassword: "",
};

const validationSchema = Yup.object({
	empFirstName: Yup.string()
		.matches(/^[a-zA-Z ]*$/, "Please enter a valid first name")
		.required("First name is required!"),
	empLastName: Yup.string()
		.matches(/^[a-zA-Z ]*$/, "Please enter a valid last name")
		.required("Last name is required!"),
	empHomePhone: Yup.string()
		.matches(
			/^(?:\+971|971|00971|0)?(?:50|51|52|53|54|55|56|57|58|59|2|3|4|5|6|7|8|9)\d{7}$/,
			"Please enter a valid UAE phone number"
		)
		.required("Phone number is required"),
	empXRealtyEmail: Yup.string()
		.matches(
			/^[A-Za-z0-9._%+-]+@xrealty\.ae$/,
			"Invalid work email, Please enter a valid work email! (example@xrealty.ae)"
		)
		.required("Work email address required! (example@xrealty.ae)"),
	empPassword: Yup.string()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
			"Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
		)
		.required("Password required!"),
	empConfirmPassword: Yup.string()
		.oneOf([Yup.ref("empPassword"), null], "Password must match")
		.required("Confirm Password required!"),
});

function Register() {
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfimPassword, setShowConfimPassword] = useState(false);
	const [errMessage, setErrMessage] = useState("");

	const handlePasswordShowHide = () => {
		setShowPassword(!showPassword);
	};

	const handleConfimPasswordShowHide = () => {
		setShowConfimPassword(!showConfimPassword);
	};

	const onSubmit = async (values, { resetForm }) => {
		setIsLoading(true);

		try {
			const response = await fetch(REGISTER, {
				method: "POST",
				body: JSON.stringify(values),
				headers: {
					"Content-Type": "application/json",
				},
			});

			const res = await response.json();
			setErrMessage(res);

			if (res.status === 200) {
				setIsLoading(false);
				resetForm({ values: "" });
				setShowPassword(false);
			} else {
				setIsLoading(false);
			}
		} catch (err) {
			console.error(err);
		}

		setIsLoading(false);
	};

	return (
		<div className="register">
			<div className="auth-wrapper">
				<div className="auth-container">
					<div className="auth-header">
						<img src={XR_Logo_Black} alt="XRealty Portal" />

						<h2>Create an account</h2>
						<p>Enter the details below to get started.</p>
					</div>

					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formik) => {
							return (
								<Form>
									<div className="form-container">
										<Field
											type="text"
											name="empFirstName"
											placeholder="First Name"
										/>
										<ErrorMessage
											name="empFirstName"
											component={FormErrorText}
										/>
									</div>
									<div className="form-container">
										<Field
											type="text"
											name="empLastName"
											placeholder="Last Name"
										/>
										<ErrorMessage
											name="empLastName"
											component={FormErrorText}
										/>
									</div>
									<div className="form-container">
										<Field
											type="text"
											name="empHomePhone"
											placeholder="Phone Number"
										/>
										<ErrorMessage
											name="empHomePhone"
											component={FormErrorText}
										/>
									</div>
									<div className="form-container">
										<Field
											type="email"
											name="empXRealtyEmail"
											placeholder="XR Email Address"
										/>
										<ErrorMessage
											name="empXRealtyEmail"
											component={FormErrorText}
										/>
									</div>
									<div className="form-container">
										<div className="inner-form-container">
											<Field
												type={showPassword ? "text" : "password"}
												name="empPassword"
												placeholder="Password"
												style={{ width: "78%" }}
												autoComplete="off"
											/>
											{showPassword ? (
												<FaEyeSlash
													className="password-show-hide"
													onClick={handlePasswordShowHide}
												/>
											) : (
												<FaEye
													className="password-show-hide"
													onClick={handlePasswordShowHide}
												/>
											)}
										</div>
										<ErrorMessage
											name="empPassword"
											component={FormErrorText}
										/>
									</div>
									<div className="form-container">
										<div className="inner-form-container">
											<Field
												type={showConfimPassword ? "text" : "password"}
												name="empConfirmPassword"
												placeholder="Confirm Password"
												style={{ width: "78%" }}
											/>
											{showConfimPassword ? (
												<FaEyeSlash
													className="password-show-hide"
													onClick={handleConfimPasswordShowHide}
												/>
											) : (
												<FaEye
													className="password-show-hide"
													onClick={handleConfimPasswordShowHide}
												/>
											)}
										</div>
										<ErrorMessage
											name="empConfirmPassword"
											component={FormErrorText}
										/>
									</div>

									{errMessage.status == 200 ? (
										<p className="errMsgFinal">{errMessage.message}</p>
									) : (
										<p className="errMsg">{errMessage.message}</p>
									)}

									<button type="submit">
										{isLoading ? (
											<img
												src="https://xrealty.sirv.com/Images/Loader/Infinity-loader-white.svg"
												alt="Infinity"
												className="loading"
											/>
										) : (
											<p style={{ padding: "16px 0" }}>Create account</p>
										)}
									</button>
								</Form>
							);
						}}
					</Formik>

					<div className="extra-info">
						<Link to="/">
							Already have an account?{" "}
							<span style={{ fontWeight: "600", textDecoration: "underline" }}>
								Sign In
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Register;
