import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";
import moment from "moment";
import { SET_USER } from "../../apiStrings/users";

import PersonalInformation from "./PersonalInformation";
import EmployeeStatus from "./EmployeeStatus";
import EmergencyContact from "./EmergencyContact";
import PreviousEmployment from "./PreviousEmployment";
import { ProfileUpdateToast } from "../Profile/ProfileUpdateToast";
import EmployeeBankDetails from "./EmployeeBankDetails";

const validationSchema = Yup.object().shape({
	empFirstName: Yup.string()
		.matches(/^[a-zA-Z ]*$/, "Please enter a valid first name!")
		.required("Required!"),
	empLastName: Yup.string()
		.matches(/^[a-zA-Z ]*$/, "Please enter a valid last name!")
		.required("Required!"),
	empDOB: Yup.date().required("Required!"),
	empPassportNo: Yup.string().required("Required!"),
	empPassportDOI: Yup.date().required("Required!"),
	empPassportDOE: Yup.date().required("Required!"),
	empCitizenship: Yup.string().required("Required!"),
	empReligion: Yup.string().required("Required!"),
	empGender: Yup.string().required("Required!"),
	empLangSpoken: Yup.string().required("Required!"),
	empBloodGroup: Yup.string().required("Required!"),
	empTotalExp: Yup.number("Please enter a valid experience!")
		.required("Required!")
		.positive("Please enter a valid experience!")
		.integer("Please enter a valid experience!"),
	empPOB: Yup.string().required("Required!"),
	empAddressUAE: Yup.string().required("Required!"),
	empStateUAE: Yup.string().required("Required!"),
	empPostalCodeUAE: Yup.string().required("Required!"),
	empAddressHT: Yup.string().required("Required!"),
	empStateHT: Yup.string().required("Required!"),
	empPostalCodeHT: Yup.string().required("Required!"),
	empCountryHT: Yup.string().required("Required!"),
	empHomePhone: Yup.string()
		.matches(
			/^(?:\+971|971|00971|0)?(?:50|51|52|53|54|55|56|57|58|59|2|3|4|6|7|9)\d{7}$/,
			"Please enter a valid UAE phone number"
		)
		.required("Phone number is required"),
	empPersonalEmail: Yup.string()
		.matches(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			"Invalid work email, Please enter a valid work email!"
		)
		.required("Personal email address required!"),
	empXRealtyEmail: Yup.string()
		.matches(
			/^[A-Za-z0-9._%+-]+@xrealty\.ae$/,
			"Invalid work email, Please enter a valid work email! (example@xrealty.ae)"
		)
		.required("Work email address required! (example@xrealty.ae)"),
	empFatherName: Yup.string().required("Required!"),
	empMotherName: Yup.string().required("Required!"),
	empDOE: Yup.string().required("Required!"),
	empJobTitle: Yup.string().required("Required!"),
	empVisaStatus: Yup.string().required("Required!"),
	// empEIDNo: Yup.string().matches(/^784-?[0-9]{4}-?[0-9]{7}-?[0-9]{1}$/, 'Please enter a valid Emirates ID Number!').required("Required!"),
	empEIDIssueDate: Yup.string().required("Required!"),
	empEIDExpiryDate: Yup.string().required("Required!"),
	empSalary: Yup.number("Please enter a valid salary!")
		.required("Required!")
		.positive("Please enter aa valid salary!")
		.integer("Please enter a valid salary!"),
	empCommPer: Yup.number("Please enter a valid commission percentage!")
		.required("Required!")
		.min(0, "Commission percentage must be between 0-100%!")
		.max(100, "Commission percentage must be between 0-100%!"),
	empDepartment: Yup.string().required("Required!"),
	empDriverLicense: Yup.string().required("Required!"),
	empEmgName: Yup.string().required("Required!"),
	empEmgAddress: Yup.string().required("Required!"),
	empEmgState: Yup.string().required("Required!"),
	empEmgPostal: Yup.string().required("Required!"),
	empEmgEmail: Yup.string().required("Required!"),
	empEmgWorkPhone: Yup.string().required("Required!"),
	// empEmgCellPhone: Yup.string().required("Required!"),
	empEmgRelation: Yup.string().required("Required!"),
	empPrevComName: Yup.string().required("Required!"),
	empPrevDesig: Yup.string().required("Required!"),
	empPrevOfficeMobile: Yup.string().required("Required!"),
	empPrevOfficeAddress: Yup.string().required("Required!"),
	empBankHolderName: Yup.string().required("Required!"),
	empBankName: Yup.string().required("Required!"),
	empBranchAddress: Yup.string().required("Required!"),
	empIBANNumber: Yup.string().required("Required!"),
	empAccountNumber: Yup.string().required("Required!"),
});

const Employee = () => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const [errMessage, setErrMessage] = useState("");
	const isLoggedIn = localStorage.getItem(LOCAL_STORAGE_KEY)
		? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
		: localStorage.getItem(LOCAL_STORAGE_KEY);

	const initialValues = {
		empDataValidate: isLoggedIn.empDataValidate,
		empFirstName: isLoggedIn.empFirstName,
		empMiddleName: isLoggedIn.empMiddleName,
		empLastName: isLoggedIn.empLastName,
		empHomePhone: isLoggedIn.empHomePhone,
		empXRealtyEmail: isLoggedIn.empXRealtyEmail,
		empDOB: moment(isLoggedIn.empDOB).format("YYYY-MM-DD"),
		empPassportNo: isLoggedIn.empPassportNo,
		empPassportDOI: moment(isLoggedIn.empPassportDOI).format("YYYY-MM-DD"),
		empPassportDOE: moment(isLoggedIn.empPassportDOE).format("YYYY-MM-DD"),
		empCitizenship: isLoggedIn.empCitizenship,
		empReligion: isLoggedIn.empReligion,
		empMarital: isLoggedIn.empMarital,
		empGender: isLoggedIn.empGender,
		empLangSpoken: isLoggedIn.empLangSpoken,
		empBloodGroup: isLoggedIn.empBloodGroup,
		empTotalExp: isLoggedIn.empTotalExp,
		empPOB: isLoggedIn.empPOB,
		empAddressUAE: isLoggedIn.empAddressUAE,
		empStateUAE: isLoggedIn.empStateUAE,
		empPostalCodeUAE: isLoggedIn.empPostalCodeUAE,
		empAddressHT: isLoggedIn.empAddressHT,
		empStateHT: isLoggedIn.empStateHT,
		empPostalCodeHT: isLoggedIn.empPostalCodeHT,
		empCountryHT: isLoggedIn.empCountryHT,
		empCellPhone: isLoggedIn.empCellPhone,
		empPersonalEmail: isLoggedIn.empPersonalEmail,
		empFatherName: isLoggedIn.empFatherName,
		empMotherName: isLoggedIn.empMotherName,
		empDOE: moment(isLoggedIn.empDOE).format("YYYY-MM-DD"),
		empJobTitle: isLoggedIn.empJobTitle,
		empVisaStatus: isLoggedIn.empVisaStatus,
		empEIDNo: isLoggedIn.empEIDNo,
		empEIDIssueDate: moment(isLoggedIn.empEIDIssueDate).format("YYYY-MM-DD"),
		empEIDExpiryDate: moment(isLoggedIn.empEIDExpiryDate).format("YYYY-MM-DD"),
		empSalary: isLoggedIn.empSalary,
		empCommPer: isLoggedIn.empCommPer,
		empDepartment: isLoggedIn.empDepartment,
		empDriverLicense: isLoggedIn.empDriverLicense,
		empDriverLicNo: isLoggedIn.empDriverLicNo,
		empEmgName: isLoggedIn.empEmgName,
		empEmgAddress: isLoggedIn.empEmgAddress,
		empEmgState: isLoggedIn.empEmgState,
		empEmgPostal: isLoggedIn.empEmgPostal,
		empEmgEmail: isLoggedIn.empEmgEmail,
		empEmgWorkPhone: isLoggedIn.empEmgWorkPhone,
		empEmgCellPhone: isLoggedIn.empEmgCellPhone,
		empEmgRelation: isLoggedIn.empEmgRelation,
		empPrevComName: isLoggedIn.empPrevComName,
		empPrevDesig: isLoggedIn.empPrevDesig,
		empPrevOfficeMobile: isLoggedIn.empPrevOfficeMobile,
		empPrevOfficeAddress: isLoggedIn.empPrevOfficeAddress,
		empBankHolderName: isLoggedIn.empBankHolderName,
		empBankName: isLoggedIn.empBankName,
		empBranchAddress: isLoggedIn.empBranchAddress,
		empIBANNumber: isLoggedIn.empIBANNumber,
		empAccountNumber: isLoggedIn.empAccountNumber,
		empSWIFTCode: isLoggedIn.empSWIFTCode,
	};

	const onSubmit = async (values, { resetForm }) => {
		setIsLoading(true);

		try {
			const response = await fetch(SET_USER, {
				method: "POST",
				body: JSON.stringify(values),
				headers: {
					"Content-Type": "application/json",
				},
			});

			const res = await response.json();
			setErrMessage(res);

			if (res.status === 200) {
				setIsLoading(false);
				resetForm({ values: "" });
				localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res.data));
				navigate("/dashboard");
			} else {
				setIsLoading(false);
			}
		} catch (err) {
			console.error(err);
		}

		setIsLoading(false);
	};

	if (!isLoggedIn) return null;

	return (
		<>
			<ProfileUpdateToast
				empDataValidate={isLoggedIn.empDataValidate}
				empFirstName={isLoggedIn.empFirstName}
				empLastName={isLoggedIn.empLastName}
			/>

			<div className="personal-information">
				<div className="hkform-wrapper">
					<div className="hkform-container">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{(formik) => {
								return (
									<Form>
										<PersonalInformation />
										<EmployeeStatus />
										<EmployeeBankDetails />
										<EmergencyContact />
										<PreviousEmployment />

										{errMessage.status == 200 ? (
											<p className="errMsgFinal">{errMessage.message}</p>
										) : (
											<p className="errMsg">{errMessage.message}</p>
										)}

										<button type="submit">
											{isLoading ? (
												<img
													src="https://xrealty.sirv.com/Images/Loader/Infinity-loader-white.svg"
													alt="Infinity"
													className="loading"
												/>
											) : (
												<p style={{ padding: "16px 0" }}>Update your profile</p>
											)}
										</button>
									</Form>
								);
							}}
						</Formik>

						{/* <div className="extra-info">
                            <NavLink to="/">
                                Already have an account? <span style={{ fontWeight: "600", textDecoration: "underline" }}>Sign In</span>
                            </NavLink>
                        </div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default Employee;
